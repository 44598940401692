<template>
    <div>
        <div class="search">
            <div class="a-inline-block">
                <label for="">关键字</label><a-input v-model="key" size="large" placeholder="根据任务描述搜索" />
            </div>
            <div class="a-inline-block">
                <label for="">任务类型</label>
                <a-select size="large" v-model="type">
                    <a-select-option :value="999">全部</a-select-option>
                    <a-select-option v-for="(item,index) in taskType" :key="item" :value="Number(index+1)">{{item}}</a-select-option>
                </a-select>
            </div>
            <div class="a-inline-block">
                <label for="">任务状态</label>
                <a-select size="large" v-model="status">
                    <a-select-option :value="999">全部</a-select-option>
                    <a-select-option v-for="(item,index) in taskStatus" :key="index" :value="item.value">{{item.text}}</a-select-option>
                </a-select>
            </div>
            <div class="a-inline-block">
                <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                <a-button size="large" @click="reset">重置</a-button>
            </div>
            
        </div>
        <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination">
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <!-- <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a>
                <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a>
                
                <a-popconfirm title="确定要关闭吗?" @confirm="() => off(record.id)">
                    <a class="action del" href="javascript:;">关闭</a>
                </a-popconfirm>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;">删除</a>
                </a-popconfirm> -->
                
                <a class="action" href="javascript:;" @click="finishTask(record.id)" v-if="record.status != '完成'&& record.status != '取消'">完成</a>
                       
            </template>
        </a-table>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    data() {
        return {
            key: "",
            type: null,
            taskType: ["打电话","发短信","发微信","发邮件","分享文章","分享表单","分享文件","分享商品","客户拜访","其他"],
            status: 999,
            taskStatus: [{text:"取消",value:"0"},{text:"新建",value:"1"},{text:"完成",value:"3"}],
            columns:[
                { title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                { title: '公司名称', dataIndex: 'name'},
                { title: '任务类型', dataIndex: 'type', key: '2'},
                { title: '任务描述', dataIndex: 'task_content', key: '1'},
                { title: '创建人', dataIndex: 'creater_uid', key: '3' },
                { title: '状态', dataIndex: 'status', key: '4' },
                { title: '完成时间', dataIndex: 'endtime'},
                { title: '是否延期', dataIndex: 'isdelay'},
                { title: '开始时间', dataIndex: 'start', key: '5' },
                { title: '结束时间', dataIndex: 'end', key: '6' },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            data:[],

            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				showTotal: total => `共 ${total} 条`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    this.showSizeChange(current, pageSize)
                },
			},
        };
    },
    mounted: function(){
        this.getTask();
    },
    methods: {
        // 搜索
        onSearch(){
            this.pagination.current=1;
            this.getTask();
        },
        reset(){
            this.key = "";
            this.type = null;
            this.status = null;
            this.getTask();
        },
        getTask() {
            if(this.type==999){
              this.type='';
            }
            if(this.status==999){
              this.status='';
            }
            requestXml("/scrm/Task/getMyFollowedList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].start = getDateTime(res.list[i].start);
                    res.list[i].end = getDateTime(res.list[i].end);
                    res.list[i].endtime = getDateTime(res.list[i].endtime);
                    
                    if(res.list[i].isdelay == 1){
                        res.list[i].isdelay = "是";
                    }else{
                        res.list[i].isdelay = "否";
                    }
                    
                    res.list[i].status = this.taskStatus[Number(res.list[i].status)];
                    res.list[i].type = this.taskType[Number(res.list[i].type-1)]
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;
            },{form: "myfollowed","content": this.key,"type": this.type,"status": this.status,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            this.pagination.current = current;
            this.getTask(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getTask(); //向后端发送请求
        },
        // // 查看
        // showDetail(id){
        //     this.$router.push({
        //         path: "/scrm_pc/task/taskDetail",
        //         query: {
        //             id: id,
        //         }
        //     });
        // },
        // // 编辑
        // editTask(id){
        //     this.$router.push({
        //         path: "/scrm_pc/task/taskAdd",
        //         query: {
        //             id: id,
        //         }
        //     });
        // },
        // // 关闭
        // off(id){
        //     requestXml("/scrm/Task/setClose","POST",(res) => {
        //         console.log(res)
        //         this.getTask();
        //     },{"id": id})
        // },
        // // 删除
        // onDelete(id){
        //     requestXml("/scrm/Task/del","POST",(res) => {
        //         console.log(res)
        //         this.getTask();
        //     },{"id": id})
        // }
        // 完成
        finishTask(id){
            requestXml("/scrm/Task/setDone","POST",(res) => {
                console.log(res)
                this.getTask();
            },{"id": id})
        },
        
    },
    
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
</style>

